<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewDriverForm"
        ref="addNewDriverForm"
        v-model="isFormValid"
        class="custom-form pt-8"
        @submit.prevent="submitNewDriverForm"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Imię"
              v-model="driver.firstName"
              :rules="[rules.required]"
              placeholder="Wpisz imię kierowcy"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Nazwisko"
              v-model="driver.lastName"
              :rules="[rules.required]"
              placeholder="Wpisz nazwisko kierowcy"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Login"
              v-model.trim="driver.login"
              :rules="[rules.required, rules.noSpaces]"
              placeholder="Utwórz login kierowcy"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Hasło"
              v-model.trim="driver.password"
              validate-on-blur
              :rules="[rules.required, rules.password]"
              placeholder="Utwórz hasło kierowcy"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Adres email"
              v-model.trim="driver.email"
              :rules="[rules.email]"
              placeholder="Wpisz email kierowcy"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Nr PESEL"
              v-model="driver.pesel"
              type="number"
              :rules="[rules.peselNumber]"
              validate-on-blur
              placeholder="Wpisz numer PESEL kierowcy"
            />
          </v-col>
          <v-col
            cols="12"
            class="pb-0"
          >
            <v-text-field
              outlined
              label="Nr prawa jazdy"
              v-model="driver.licenseNumber"
              placeholder="Wpisz numer prawa jazdy kierowcy"
            />
          </v-col>

          <v-col
            cols="12"
            class="pt-0"
          >
            <div class="checkbox-container">
              <v-checkbox v-model="driver.bLicense" />
              Kat. B
            </div>
          </v-col>
          <v-col cols="12">
            <div class="checkbox-container">
              <v-checkbox v-model="driver.cLicense" />
              Kat. C
            </div>
          </v-col>
          <v-col cols="12">
            <div class="checkbox-container">
              <v-checkbox v-model="driver.ceLicense" />
              Kat. CE
            </div>
          </v-col>
          <v-col
            cols="12"
            class="mb-6"
          >
            <div class="checkbox-container">
              <v-checkbox v-model="driver.otherLicense" />
              Kat. Inna
            </div>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              outlined
              :items="terminals"
              label="Terminal"
              v-model="driver.terminalId"
              item-text="name"
              item-value="id"
              placeholder="Wybierz terminal"
              dense
            >
              <template #item="{item}">
                <div class="w-100 d-flex justify-space-between">
                  {{ item.name }}
                  <span
                    v-if="item.driver"
                    class="error--text"
                  >({{ item.driver.fullName }})</span>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              outlined
              :items="vehicles"
              label="Pojazd"
              v-model="driver.vehicleId"
              item-text="parsedData"
              item-value="id"
              placeholder="Wybierz pojazd"
              dense
            >
              <template #item="{item}">
                <div class="w-100 d-flex justify-space-between">
                  {{ item.parsedData }}
                  <span
                    v-if="item.driver"
                    class="error--text"
                  >({{ item.driver.fullName }})</span>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addNewDriverForm"
      >
        Dodaj Kierowcę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import rules from '../../utils/validators'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data () {
    return {
      rules,
      isFormValid: true,
      driver: {
        firstName: null,
        lastName: null,
        login: null,
        password: null,
        email: null,
        pesel: null,
        licenseNumber: null,
        bLicense: false,
        cLicense: false,
        ceLicense: false,
        otherLicense: false,
        vehicleId: null,
        terminalId: null,
      }
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      vehicles: state => state.vehicles.items,
      terminals: state => state.terminals.items,
      isProcessing: state => state.drivers.isProcessing
    })
  },
  methods: {
    ...mapActions({
      addNewDriver: 'drivers/addNewItem',
      getDrivers: 'drivers/getItems',
      getVehicles: 'vehicles/getItems',
      getTerminals: 'terminals/getItems',
      closeDialog: 'layout/closeDialog',
      showSnackbar: 'snackbar/showSnackbar'
    }),
    submitNewDriverForm () {
      this.$refs.addNewDriverForm.validate()
      if (this.isFormValid) {
        const params = {
          ...this.driver,
          departmentId: this.department.id
        }
        this.addNewDriver({ params })
          .then(() => {
            this.getDrivers()
            // two calls below are required because their ownership has changed
            this.getVehicles()
            this.getTerminals()
            this.closeDialog()
          })
      }
    }
  }
}
</script>
